<template>
  <div>
    <b-modal
      v-model="show"
      hide-header
      hide-footer
      hide-header-close
      centered
      no-close-on-esc
    >
      <div class="p-3- page6-2" style="width: 90%">
        <div class="fw-600 text-center mb-3" v-html="title"></div>
        <div v-if="message" class="text-muted mb-4 text-center" v-html="message"></div>
        <div
          v-if="redMessage"
          class="text-ee1 mb-4 text-center"
          v-html="redMessage"
        ></div>
        <div v-if="okText" class="text-center">
          <b-button @click="okClick" variant="ee1" pill class="px-5 fw-400">{{
            okText
          }}</b-button>
        </div>
        <div v-if="showCancel" class="text-center mt-3">
          <p @click="cancelClick" class="cursor text-7e mb-0">
            {{ cancelText }}
          </p>
        </div>
        <div v-if="showThirdBtn" class="text-center mt-3">
          <p @click="thirdBtnClick" class="cursor text-7e mb-0">
            {{ thirdText }}
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "common-modal",
  data() {
    return {
      title: "",
      message: "",
      redMessage: "",
      okText: "",
      showCancel: true,
      cancelText: "",
      thirdText: "",
      showThirdBtn: false,
      show: false,
      metaData: null,
    };
  },
  methods: {
    showModal(opts) {
      let self = this;
      self.title = opts.title;
      self.message = opts.message;
      self.redMessage = opts.redMessage;
      self.okText = opts.okText;
      self.showCancel = _.get(opts, "showCancel", true);
      self.cancelText = opts.cancelText;
      self.showThirdBtn = _.get(opts, "showThirdBtn", false);
      self.thirdText = _.get(opts, "thirdText", false);
      self.metaData = opts.metaData;
      self.show = true;
    },
    hideModal() {
      let self = this;
      self.title = "";
      self.message = "";
      self.okText = "";
      self.showCancel = true;
      self.cancelText = "";
      self.metaData = null;
      self.show = false;
      self.showThirdBtn = false;
      self.thirdText = "";
    },
    okClick() {
      this.$emit("okEvent", this.metaData);
    },
    cancelClick() {
      this.$emit("cancelEvent", this.metaData);
    },
    thirdBtnClick() {
      this.$emit("thirdEvent", this.metaData);
    },
  },
};
</script>