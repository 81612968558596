<template>
  <div class="bg-faf min-h100 text-1d">
    <div>
      <topName ref="topNav" :showMobileTop="false" />

      <!-- <div style="position: absolute;
    width: 180px;
    left: 50%;
    transform: translateX(-50%);
    top: 8px;">
        <b-img class="w-100" src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.0/LS_text_logo_red.png"></b-img>
    </div> -->
      <div v-if="client" class="page_container hideMobileTop page6-2">
        <div class="px-3">
          <div class="d-flex justify-content-between mb-3 pt-3">
            <div v-if="client.privacy_mode == 0">&nbsp;</div>
            <div v-if="client.privacy_mode == 0">
              <b-img height="20" src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/v12/Logo-v12@2x.png"></b-img>
            </div>
            <div v-else>
              <switchc class="ee1 d-inline-block" @change="changeSwitch" name="privacy_mode"
                icon="fa-light fa-lock-keyhole text-ee1" :value="1" :readonly="true"></switchc> <span
                class="fw-600 d-inline-block ml-2" style="position: relative;
    top: -5px;">{{ $t('off_privacy_mode') }}?</span>
              <!-- <b-button
                pill
                @click="trunOffPrivacyMode"
                variant="ee1"
                class="px-3"
                >{{ $t("you_privacy_mode") }}</b-button
              > -->
            </div>
            <div>
              <i @click="filterClick" class="fa-light fa-sliders-simple fs-30 text-1d"></i>
            </div>
          </div>

          <div v-if="isLoading" class="center_center text-center" style="height: 140px">
            <div class="x-center">
              <b-spinner variant="secondary" class="ls_loading_spinner"></b-spinner>
              <b-img class="ls_loading_spinner_logo"
                src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/logos/logo6.2@3x.png"></b-img>
            </div>
          </div>

          <!-- <div v-if="!isLoading && !hasMatches" class="center_center text-center">
            <b-img width="80" class="mb-4" src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.0/no_matches_icon.png"></b-img>
            <p class="fw-600">No more matches available</p>
            <p class="mb-4">There are no potential matches in your ares.<br/>Change your match filters or check again later.</p>
            <b-button @click="goMatchFilter" variant="ls-red2" class="fw-600">Change match filters</b-button>
        </div> -->

          <div v-if="!isLoading && hasMatches">
            <div v-if="currentMatch">
              <div class="position-relative">
                <b-img :src="currentMatch.target_client.primary_photo" style="min-height: 200px"
                  class="w-100 rounded-10"></b-img>
                <div class="user-info px-3 py-1">
                  <h5 class="fw-700">
                    {{ currentMatch.target_client.first_name }},
                    {{ currentMatch.target_client.age }}
                  </h5>
                  <div class>
                    <p v-if="currentMatch.target_client.job_title" class="text-truncate fw-400">
                      <!-- <i class="fa fa-suitcase text-gold"></i> -->
                      <i class="lsicon job_title_w lsicon-18 mr-1"></i>
                      {{ currentMatch.target_client.job_title }}
                    </p>
                    <p v-if="currentMatch.target_client.university_name" class="text-truncate mb-2 fw-400">
                      <i class="lsicon schoolname_w mr-1"></i>{{ currentMatch.target_client.university_name }}
                    </p>
                  </div>
                </div>
              </div>

              <div v-if="currentMatch.target_client.self_intro" class="py-3">
                <p class="text-ee1 fw-600 mb-2">{{ $t("about_me_txt") }}</p>
                <p class="fw-600 text-7e">
                  {{ currentMatch.target_client.self_intro }}
                </p>
              </div>

              <div class="mb-4">
                <p class="text-ee1 fw-600 mb-3">{{ $t("my_basics") }}</p>

                <div class="text-7e">
                  

                  <span class="userAttrItem" v-if="currentMatch.target_client.height"><i
                      class="fa-light fa-ruler-vertical text-ee1"></i>
                    <!-- <i class="lsicon self-height-gold lsicon-16"></i> -->
                    {{ currentMatch.target_client.height }} cm
                  </span>

                  <span class="userAttrItem" v-if="currentMatch.target_client.ethnicity"><i
                      class="fa-light fa-earth-europe text-ee1"></i>
                    {{ currentMatch.target_client.ethnicity }}
                  </span>

                  <span class="userAttrItem" v-if="currentMatch.target_client.education_level">
                    <i class="fa-light fa-graduation-cap text-ee1"></i>
                    {{ currentMatch.target_client.education_level }}
                  </span>
                  <span class="userAttrItem" v-if="currentMatch.target_client.annual_income"><i
                      class="fa-light fa-circle-dollar text-ee1"></i>
                    <!-- <i class="lsicon self-annual_income-gold lsicon-16"></i> -->
                    {{ currentMatch.target_client.annual_income }}
                  </span>
                  <span class="userAttrItem" v-if="currentMatch.target_client.relationship_status">
                    <i class="fa-light fa-rings-wedding text-ee1"></i>
                    {{ currentMatch.target_client.relationship_status }}
                  </span>
                  <span class="userAttrItem" v-if="currentMatch.target_client.child_plans">
                    <i class="fa-light fa-family-pants text-ee1"></i>
                    {{ currentMatch.target_client.child_plans }}
                  </span>

                  <span class="userAttrItem" v-if="currentMatch.target_client.religion">
                    <i class="fa-light fa-hands-praying text-ee1"></i>
                    {{ currentMatch.target_client.religion }}
                  </span>
                  <span class="userAttrItem" v-if="currentMatch.target_client.drinking_status">
                    <i class="fa-light fa-wine-glass text-ee1"></i>
                    {{ currentMatch.target_client.drinking_status }}
                  </span>
                  <span class="userAttrItem" v-if="currentMatch.target_client.smoking_status"><i
                      class="fa-light fa-smoking text-ee1"></i>
                    {{ currentMatch.target_client.smoking_status }}
                  </span>
                  <span class="userAttrItem" v-if="currentMatch.target_client.horoscope"><i
                      class="fa-light fa-star-of-david text-ee1"></i>
                    <!-- <i class="lsicon self-horoscope-gold lsicon-16"></i> -->
                    {{ currentMatch.target_client.horoscope }}
                  </span>
                </div>
              </div>

              <div>
                <div class="mb-3" v-for="(ophoto, index) in currentMatch.target_client
                  .other_photos" :key="index">
                  <img :src="ophoto" class="w-100 rounded-10" />
                </div>
              </div>

              <div style="height: 70px" class="w-100">&nbsp;</div>
              <div class="matchButtonRow px-3">
                <div class="d-flex justify-content-center">
                  <div>
                    <!-- <button
                      v-if="currentMatch.intro.chat_total > 0"
                      @click.stop="chatBtnClick"
                      class="shadow o-btn"
                      style=""
                    >
                      <i class="lsicon chat_blue lsicon-115"></i>
                    </button> -->
                    <button @click.stop="chatBtnClick" class="btn-1d-ee1 shadow o-btn lg60 position-relative" style="">
                      <i class="fa-light fa-comment fs-30"></i>
                      <span v-if="currentMatch.target_client.msg_received_count > 0" class="countNum">{{ currentMatch.target_client.msg_received_count
                      }}</span>
                    </button>
                  </div>
                  <div>
                    <b-button @click="nextMatchClick" variant="white" class="o-btn lg ml-4 shadow" style="top: 5px">
                      <!-- <i class="fa-regular fa-arrow-right"></i> -->
                      <i class="fa-light fa-arrow-right fs-30 text-7e"></i>
                    </b-button>
                  </div>

                  <!-- <div>
                    <b-button class="matchActBtn likeBtn">
                        <i class="fa fa-heart"></i>
                    </b-button>
                </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <likeChatSentPopup ref="likeChatSentPopup" @afterAction="afterSentMessage" :isBlackBg="true" />
      <alertModal ref="alertModal" @okEvent="alertOkClick" @cancelEvent="alertCancelClick" />
    </div>
  </div>
</template>
<script>
import topName from "../common/topNav";
import likeChatSentPopup from "../common/likeChatSentPopup.vue";
import alertModal from "../common/alertModal";
import helper from "../../utils/helper";
import switchc from "../common/switchc";
export default {
  name: "lsm-new-matches",
  components: {
    topName,
    likeChatSentPopup,
    alertModal,
    switchc
  },
  data() {
    return {
      client: null,
      isLoading: false,
      hasMatches: true,
      matches: null,
      currentMatchIndex: 0,
      currentMatch: null,
      calledNewMatches: false,
      isJumping: false,
      privacy_mode: 1,
      currentMatchTest: {
        first_name: "Jenny",
        age: 32,
        height: 174,
        job_title: "Marketing manager",
        ethnicity: "Asian",
        education_level: "Master's degree",
        annual_income: "HK$200k - 300k",
        relationship_status: "Never married",
        child_status: "Want child",
        religion: "Agnostic",
        smoking_status: "Non-smoker",
        drinking_status: "Non-drinker",
        university_name: "Sydney University",
        primary_photo:
          "https://cdn-cf2.lovestruck.com/upload-m/crop_1652631291.png",
        other_photos: [
          "https://cdn-cf2.lovestruck.com/upload-m/crop_1640571181.png",
          "https://cdn-cf2.lovestruck.com/upload-m/chat/2021/12/298df0bee5dd9b74fe2afa5e1d5ad014.png",
        ],
        self_intro:
          "A stable-minded girl and good listener, also be confident towards life. I love travelling, reading books and sharing my opinion about the books via online platform. Also, I am fond of attending workshops to learn flower arrangement and baking, etc.",
      },
    };
  },
  mounted() {
    let self = this;
    let callFun = "";
    // console.log("new match mounted...");
    self.$api
      .getClientMe(self.$store.state)
      .then((rs) => {
        self.client = _.get(rs, "client");
        // self.client.privacy_mode = 1;
        // self.client.membership_level_id = 3;
        // self.client.is_verified = 1;
        // console.log('currentData', self.currentData)
        if (self.client.membership_has_expired == 1) {
          self.isJumping = true;
          self.$router.push({ name: "renew-payment" });
        } else if (self.client.membership_level_id < 2) {
          self.isJumping = true;
          // self.$router.push({ name: "matchmaking-callback" });
          self.$router.push({ name: "op-match-list" });
        } else if (!self.client.is_verified) {
          self.isJumping = true;
          self.$router.push({ name: "profile-unverified" });
        } else if (self.client.client_status_id == -1) {
          self.isJumping = true;
          self.$router.push({ name: "profile-paused" });
        } else {
          //   console.log("sd fsdfdsfsdfdsfdf");
          if (self.client.total_matchmaker_msg == 0) {
            callFun = "getSendFirstMatchmakerMsg";
            return self.$api.getSendFirstMatchmakerMsg("", self.$store.state);
          } else if (self.client.total_match <= 3) {
            callFun = "getGenerateInitialIntros";
            return self.$api.getGenerateInitialIntros("", self.$store.state);
          } else {
            self.getMatchmakingNewMatches();
            console.log("call 1...");
            new Error("called 1");
            return false;
          }
        }
      })
      .then((rs) => {
        if (self.isJumping) {
          console.log("isJumping 1...");
          return false;
        }
        console.log("callFun0", callFun);

        if (
          callFun == "getSendFirstMatchmakerMsg" &&
          self.client.total_match <= 3
        ) {
          return self.$api.getGenerateInitialIntros("", self.$store.state);
        } else {
          self.getMatchmakingNewMatches();
          console.log("call 2...");
          new Error("called 2");
          return false;
        }
      })
      .then((rs) => {
        if (self.isJumping) {
          console.log("isJumping 2...");
          return false;
        }
        self.getMatchmakingNewMatches();
        console.log("call 3...");
        new Error("called 3");
        return false;
      })
      .catch((err) => {
        console.log("err", err);
      });
  },
  methods: {
    changeSwitch() {
      this.trunOffPrivacyMode();
    },
    afterSentMessage(data) {
      let self = this;
      if (data.type != "close") {
        self.$refs.likeChatSentPopup.closeModal();
        self.goToNextMatch("chat");
      }
    },
    trunOffPrivacyMode() {
      let self = this;
      self.$refs.alertModal.showModal({
        title: `${self.$t("off_privacy_mode")} ?`,
        message: `<p class="mb-3 text-center">${self.$t(
          "off_privacy_mode_desc1"
        )}</p>
                <p class="text-center">${self.$t(
          "off_privacy_mode_desc2"
        )}</p>
                `,//6672,6673
        okText: self.$t("off_privacy_mode").toUpperCase(),//6671
        // cancelText: self.$t("cancel_txt"),
        metaData: {
          type: "off_privacy_mode",
        },
      });
    },
    alertOkClick() {
      let self = this;
      let fdata = new FormData();
      fdata.append("privacy_mode", 0);

      self.$api
        .postClientUpdate(fdata, self.$store.state)
        .then((rs) => {
          self.client.privacy_mode = 0;
          self.$layer.closeAll("loading");
          self.$refs.alertModal.hideModal();
        })
        .catch((err) => {
          console.log(["me error", err]);
          self.$layer.closeAll("loading");
        });
    },
    alertCancelClick() {
      let self = this;
      //   console.log("alertCancelClick", data);
      self.$refs.alertModal.hideModal();
    },
    filterClick() {
      let self = this;
      self.$router.push({ name: "profile-requirement" });
      // if(self.client.membership_level_id < 2){
      //     // self.gotPayment("gold", 4);
      // }else{
      //     self.$router.push({ name: "profile-requirement" });
      // }
    },
    chatBtnClick() {
      let self = this;
      if(self.client.membership_level_id < 2){
        self.$router.push({ name: "renew-payment" });
        return false;
      }
      let target = _.get(self.currentMatch, "target_client");
      // self.goToNextMatch("no next");
      // self.$refs.likeChatSentPopup.showModal({
      //         match:target,
      //         isLikeSent:false,
      //         isPaid:true,
      //         isChatAction: true,
      //     })
      //     return false;

      if (_.get(self.currentMatch, "intro.chat_total", 0) == 0) {
        self.$refs.likeChatSentPopup.showModal({
          match: target,
          isLikeSent: false,
          isPaid: true,
          isChatAction: true,
        });
      } else {
        self.$router.push({
          name: "client-chat",
          query: {
            clientId: helper.lshash.encode(
              _.get(self.currentMatch, "target_client.client_id")
            ),
          },
        });
        self.goToNextMatch("chat");
      }

      //
      // msg_received_count
    },
    nextMatchClick() {
      let self = this;
      self.isLoading = true;
      self.goToNextMatch();
      // setTimeout(function(){

      //     self.nextMatchCard('next')
      // }, 3000)
    },
    nextMatchCard(ref) {
      let self = this;
      //   self.$layer.loading();
      //   console.log("nextMatchCard=", ref);

      if (self.currentMatchIndex >= self.matches.length) {
        self.$router.push({ name: "matchmaking-match-list" });
        self.$layer.closeAll("loading");
        return false;
      }

      //   if (
      //     !self.isLoading &&
      //     !self.isEnd &&
      //     self.totalMatch >= self.query.size &&
      //     self.currentMatchIndex > self.matches.length - 5
      //   ) {
      //     console.log("loadNextAll");
      //     self.loadNextAll();
      //     return false;
      //   }
      self.isLoading = false;
      self.currentMatch = _.get(self.matches, self.currentMatchIndex);
      if (!self.currentMatch) {
        self.hasMatches = false;
        return false;
      }

      //   console.log("self.currentMatch=", self.currentMatch);

      let target = _.get(self.currentMatch, "target_client");
      //   target.photos = [target.primary_photo];

      target.other_photos = [];
      if (target.photos && target.photos.length > 1) {
        for (let op of target.photos) {
          if (op != target.primary_photo) {
            target.other_photos.push(op);
          }
        }
        // target.other_photos = target.photos;
        // target.other_photos = _.pull(target.other_photos, target.primary_photo);
      }

      target.photos = [];
      self.currentMatch.target_client = target;

      self.currentMatchIndex++;
    },
    goToNextMatch() {
      let self = this;

      //   self.nextMatchCard();

      //   return false;
      let pdata = new FormData();
      pdata.append(
        "intro_id",
        _.get(self.currentMatch, "intro.client_intro_id", 0)
      );
      pdata.append(
        "target_client_id",
        _.get(self.currentMatch, "target_client.client_id")
      );
      pdata.append("act_type", "setView");
      self.matchAction(pdata, "setView");
    },
    getMatchmakingNewMatches() {
      let self = this;

      if (self.calledNewMatches) {
        console.log("called getMatchmakingNewMatches");
        return false;
      }

      self.isLoading = true;
      self.calledNewMatches = true;
      //   return false;
      self.$api
        .getMatchmakingNewMatches("", self.$store.state)
        .then((rs) => {
          self.hasMatches = true;
          self.matches = _.get(rs, "matches");

          if (self.matches.length > 0) {
            self.nextMatchCard("init");
          } else {
            self.hasMatches = false;
            self.$router.push({ name: "matchmaking-match-list" });
          }

          self.$layer.closeAll("loading");
          // new Error('called getMatchmakingNewMatches')
        })
        .catch((err) => {
          self.calledNewMatches = false;
          console.log("getMatchmakingNewMatches", err);
        });
    },

    matchAction(fdata, type) {
      let self = this;
      // let index = _.findIndex(self.list, { client_intro_id: intro_id });
      // self.$layer.loading();
      self.$api
        .postDateAction(fdata, self.$store.state)
        .then((rs) => {
          // console.log(rs);
          self.$layer.closeAll("loading");
          if (rs.success) {
            // console.log("rs", rs);
            if (type == "setView") {
              self.nextMatchCard("match action");
            }
          } else {
            // if (_.get(rs, "popup_url", false)) {
            //   self.gotoUpgrade(rs);
            // } else {
            self.$layer.msg(_.get(rs, "message", ""));
            // }
          }
        })
        .catch((err) => {
          //   console.log("catch", err);
          self.$layer.closeAll("loading");
          //   self.$layer.msg(_.get(err, "response.data.error.body"));
          //self.afterAction(intro_id, type);
          self.$bvModal.msgBoxOk(_.get(err, "response.data.error.body"), {
            size: "md",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: self.$t("Ok"),
            hideHeaderClose: true,
            centered: true,
          });
          console.log(err);
        });
    },
  },
};
</script>